import React from 'react';
import styled from 'styled-components';

// Components
import ImprintWrapper from '../components/imprint';
// data
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

// utils
import withTranslate from '../utils/withTransWrapper';

import Footer from '../components/layout/footer';
import SEO from '../components/seo';

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: ${({ theme }) => theme.color.textWhite};
`;

const Imprint = ({ data }) => {
  const prismicImprint = data.prismicImprintPage;
  if (!prismicImprint) return null;
  const document = prismicImprint.data;
  return (
    <>
      <SEO title="Impressum" />
      <Wrapper>
        <ImprintWrapper
          title={RichText.asText(document.imprint_title.raw)}
          detail={RichText.asText(document.imprint_detail.raw)}
          address={RichText.asText(document.imprint_address.raw)}
          titleEmail={document.imprint_email.url}
          textEmail={'Email:'}
          staticEmail={'mail@20scoops.com'}
          titleWeb={document.imprint_website.url}
          textWeb={'Web:'}
        />
        <Footer contactShow={false} />
      </Wrapper>
    </>
  );
};

export default withTranslate(Imprint);

export const query = graphql`
  query {
    prismicImprintPage {
      data {
        imprint_address {
          raw
        }
        imprint_detail {
          raw
        }
        imprint_email {
          url
        }
        imprint_title {
          raw
        }
        imprint_website {
          url
        }
      }
    }
  }
`;
